import React, { useContext } from "react";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import { CLOSE_CART } from "../reducers/CartReducer";
import { Link } from "react-router-dom";
import {
  getBitmap,
  getPriceString,
  getEncodedProductStyle,
  getEncodedProductColor,
} from "../helpers";
import { CLIENT, GTN } from "../constants";

const CartProduct = ({ productID, size, addLogo }) => {
  const { eventID, products, customUnits, discountPercentage } =
    useContext(EventContext);
  const { cartDispatch } = useContext(CartContext);
  const product = products[productID];
  const productStyle = getEncodedProductStyle(product);
  const productColor = getEncodedProductColor(product);
  const productPath = product
    ? `/${eventID}/store/product/${productStyle}/${productColor}`
    : `/${eventID}`;

  if (!product) return null;

  // Calculate the product price with the logo option
  const shouldAddLogoPrice =
    addLogo && customUnits !== "Units" && CLIENT === GTN;
  const calculatePrice = product.price + (shouldAddLogoPrice ? 5 : 0);

  const lastPrice =
    calculatePrice - calculatePrice * (parseFloat(discountPercentage) / 100);

  return (
    <div className="cartProduct">
      <Link
        onClick={() => cartDispatch({ type: CLOSE_CART })}
        to={productPath}
        className="storefrontProduct"
      >
        <img
          alt="product"
          src={getBitmap(product.image)}
          className="productImage skeleton"
        />
      </Link>
      <div className="productDetails">
        <span className="productName">{product.styleNameLong}</span>
        <span className="productSize">Size: {size}</span>
        <span className="productPrice">
          {discountPercentage && discountPercentage > 0 ? (
            <div style={{ display: "flex", gap: "10px" }}>
              <span
                className="productPrice"
                style={{ textDecoration: "line-through" }}
              >
                {getPriceString(calculatePrice, customUnits)}
              </span>
              <span className="productPrice">
                {getPriceString(lastPrice, customUnits)}
              </span>
            </div>
          ) : (
            <span className="productPrice">
              {getPriceString(calculatePrice, customUnits)}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default CartProduct;
