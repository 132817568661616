import { useEffect, useState, useContext, useCallback } from "react";
import { functions } from "../firebase";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import { ADD_PAYPAL } from "../reducers/CartReducer";
import { PayPalButton } from "react-paypal-button-v2";
import { red } from "@material-ui/core/colors";
import { AuthContext } from "../context/AuthContext";

const createPayPalOrder = functions.httpsCallable(
  "httpsOnCall-createPayPalOrder"
);
function purgeNaNRecursive(obj) {
  if (Array.isArray(obj)) {
    return obj
      .map((val) => purgeNaNRecursive(val))
      .filter((val) => !Number.isNaN(val));
  } else if (typeof obj === "object" && obj !== null) {
    let newObj = {};
    for (let prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        let value = obj[prop];
        if (!Number.isNaN(value)) {
          newObj[prop] = purgeNaNRecursive(value);
        }
      }
    }
    return newObj;
  } else {
    return obj;
  }
}

const PayPal = ({ watch }) => {
  const { userData } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [fullCartProducts, setFullCartProducts] = useState({});
  const { products, dropShipCharge, payPalPayee, customUnits } =
    useContext(EventContext);
  const { cartState, cartDispatch, dropShip, submitOrder } =
    useContext(CartContext);
  const url = window.location.href;
  const parts = url.split("/");
  const idEvent = parts[parts.length - 2];

  const eventLocal = userData?.events?.find(
    (event) => event.eventId === idEvent
  );

  const lastBudget = eventLocal.payed !== undefined
  ? Math.max(Number(cartState?.budget) - eventLocal?.payed, 0)
  : 0;
  const createOrder = useCallback(() => {
    setLoading(true);
    const cartProducts = Object.values(fullCartProducts);

    // Creates a PayPal Order with Firebase functions and PayPal client API
    return createPayPalOrder({
      cartProducts: purgeNaNRecursive(cartProducts),
      budget: lastBudget + cartState.payPalApplied,
      total: cartState.total,
      dropShipCharge: dropShip ? dropShipCharge : 0,
      payee: payPalPayee,
      customUnits,
    })
      .then(({ data }) => {
        setLoading(false);
        return data.result.id;
      })
      .catch((err) => {
        console.log(`Error creating PayPal order: ${err}`);
        setLoading(false);
      });
  }, [
    fullCartProducts,
    cartState,
    dropShip,
    dropShipCharge,
    payPalPayee,
    customUnits,
  ]);

  const renderPaypalButton = useCallback(() => {
    if (!window.paypal) return;

    const disable = !(
      watch("firstName")?.length > 0 &&
      watch("lastName")?.length > 0 &&
      watch("street")?.length > 0 &&
      watch("city")?.length > 0
    );

    return (
      <>
        {disable && (
          <button
            disabled={disable}
            style={{
              padding: "12px",
              background: "gray",
              color: "white",
              fontWeigth: "bold",
              cursor: "not-allowed",
              borderRadius: "20px",
              width: "100%",
              fontSize: "16px",
              marginBottom: "10px",
            }}
          >
            Paypal
          </button>
        )}
        {!disable && (
          <PayPalButton
            disabled={loading}
            style={{
              color: "blue",
              shape: "pill",
            }}
            fundingSource={window.paypal.FUNDING.PAYPAL}
            onApprove={async (data, actions) => {
              const response = await actions.order.capture();
              const amountPaid = response.purchase_units.reduce((sum, unit) => {
                return sum + unit.amount.value;
              }, 0);
              console.log(`Paid ${amountPaid} with PayPal`);
              cartDispatch({ type: ADD_PAYPAL, amount: amountPaid });
              await submitOrder();
            }}
            onError={(error) => {
              alert(`There was an error with the PayPal transaction: ${error}`);
            }}
            createOrder={async (data, actions) => {
              return await createOrder();
            }}
          />
        )}
        {disable && (
          <span
            style={{
              color: "red",
              fontWeigth: "800",
              fontSize: "16px",
              marginTop: "20px",
            }}
          >
            *Complete all shipping information.
          </span>
        )}
      </>
    );
  }, [cartDispatch, createOrder, loading, submitOrder, watch]);

  // Populate cart products with full product data
  useEffect(() => {
    if (!cartState.products) return;
    const cartProducts = Object.keys(cartState.products).reduce(
      (result, key) => {
        const cartProduct = cartState.products[key];
        const xID = cartProduct.product;
        return {
          ...result,
          [key]: {
            ...cartProduct,
            product: products[xID],
          },
        };
      },
      {}
    );
    setFullCartProducts(cartProducts);
  }, [products, cartState]);

  return (
    <div>
      {renderPaypalButton()}
      {/* <div ref={paypal}/> */}
    </div>
  );
};

export default PayPal;
