import React from 'react';

const InvalidEvent = () => {
  return(
    <div className="outofservice-message">
      <i className="logo"></i>
      <h1>Invalid/Incomplete Event</h1>
      <h2>This event has invalid or incomplete data.</h2>
      <p>Please have an event manager check this event in the Event Management tool</p>
    </div>
  );
}

export default InvalidEvent;
