import { updateUser } from "./auth";
import { firestore } from "./firebaseConfig";

const url = window.location.href;
const parts = url.split("/");
const eventId = parts[parts.length - 2];

export default async function updateEventPayed(userId, payedAmount, user) {
  try {
    const userRef = firestore.collection("users").doc(userId);
    const userDoc = await userRef.get();

    if (!userDoc.exists) {
      console.log("User document does not exist.");
    }

    const events = userDoc.data().events || [];
    const eventIndex = events.findIndex((event) => event.eventId === eventId);

    if (eventIndex < 0) {
      updateUser(user, eventId);
      updateEventPayed(userId, payedAmount, user);
    }

    events[eventIndex].payed =
      (events[eventIndex]?.payed || 0) + payedAmount || 0;

    await userRef.update({ events });
  } catch (error) {
    console.log("Error updating event payed amount:", error);
  }
}
