import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import { confirmCurrentPassword, updatePassword } from "../auth";
import { AuthContext } from "../context/AuthContext";
import { EventContext } from "../context/EventContext";

const SetPassword = () => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { user, newUser, setNewUser } = useContext(AuthContext);
  const { eventID } = useContext(EventContext);
  const errorRef = useRef("");
  const history = useHistory();

  useEffect(() => {
    document.body.className = "setPassword";
    return () => {
      document.body.className = "";
    };
  }, []);

  const submitHandler = useCallback(async (event) => {
    event.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = event.target.elements;
    if (currentPassword && !newUser) {
      const correct = await confirmCurrentPassword(currentPassword.value);
      if (!correct) {
        alert("Your current password is incorrect");
        return
      }
    }
    if (newPassword.value !== confirmPassword.value) {
      alert("Your passwords do not match");
      return;
    }
    // Update the user's password
    updatePassword(newPassword.value).then((success) => {
      if (success) {
        if (!newUser) alert("Password change was successful!")
        setPasswordChanged(true);
        setNewUser(false);
      }
    });
  }, [newUser, setNewUser]);

  if (!user || !user.uid) return null;
  if (!!eventID && passwordChanged) {
    history.push(`/${eventID}/welcome/`);
    history.go(0);
  }

  return (
    <>
    <div className="main">
      <h1>{newUser ? "Set" : "Change"} Password</h1>
      <p>{newUser ? "Please set a password so you can come back later.": null}</p>

      <p>
        Don't worry, if you forget your password, you can reset it at any time
        from the login page.
      </p>

      <form
        name="loginForm"
        onSubmit={submitHandler}
        autoComplete="off"
      >
        {/* retry message */}
        <div className="retry-message">
          <label>{errorRef.current}</label>
        </div>
        {newUser ? null : (
          <input
            name="currentPassword"
            type="password"
            className="validated-field"
            placeholder="Current Password"
            required
            autoFocus
          />
        )}
        <input
          name="newPassword"
          type="password"
          className="validated-field"
          placeholder={newUser ? "Password" : "New Password"}
          required
          autoFocus
        />{" "}
        {/* password */}
        <input
          name="confirmPassword"
          type="password"
          className="validated-field"
          placeholder="Confirm Password"
          required
        />{" "}
        {/* submit */}
        <button type="submit" className="button">
          {newUser ? "Set" : "Change"} Password
          <span></span>
        </button>
      </form>
    </div>
    </>
  );
};

export default SetPassword;
