import React, { useState, useContext, useEffect } from "react";
import { EventContext } from "../context/EventContext";
import { Link } from "react-router-dom";
import { getStyleName } from "../firestore";

const Promo = () => {
  const { eventID, featuredProduct, promoImage, promoTitle, promoText } = useContext(EventContext);
  const [featuredProductStyle, setFeaturedProductStyle] = useState("");

  useEffect(() => {
    document.body.className = "promo";
    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    if (!featuredProduct) return null;
    getStyleName(featuredProduct).then((styleName) => {
      setFeaturedProductStyle(encodeURIComponent(styleName));
    });
  }, [featuredProduct]);

  return (
    <div
      className="promoContainer"
      style={{ backgroundImage: `url("${promoImage}")` }}
    >
      <div id="promoContent">
        <h1>{promoTitle}</h1>
        <p>{promoText}</p>
        <Link to={`/${eventID}/store/product/${featuredProductStyle}`} className="button">
          See Details
        </Link>
        <Link to={`/${eventID}/store`} className="buttonSecondary">
          Start Shopping!
        </Link>
      </div>
    </div>
  );
};

export default Promo;
