import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import { AuthContext } from "../context/AuthContext";
import { EventContext } from "../context/EventContext";

const Welcome = () => {
  const { user } = useContext(AuthContext);
  const {
    eventID,
    logo,
    glamourShot,
    brandColor,
    disclaimer,
    featuredProduct,
    welcomeLetter,
  } = useContext(EventContext);

  useEffect(() => {
    document.body.className = "welcome";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="welcomeMain">
        {/* kill empty text node
         */}
        <div
          className="welcomePic skeleton"
          style={{ backgroundImage: `url("${glamourShot}")` }}
        >
          <i
            hidden={true}
            style={{ background: `url(${logo}) no-repeat center` }}
            className="logo"
          ></i>
        </div>
        {/* kill empty text node
         */}
        <article>
          <h1>
            Welcome
            {!!user.firstName && user.firstName.length !== 0
              ? `, ${user.firstName}`
              : ""}
            !
          </h1>
          <div className="welcome-message">
            {welcomeLetter.map((p, i) => {
              return <p key={i}>{p}</p>;
            })}
          </div>
          <div className="disclaimer">{disclaimer}</div>
          <Link
            to={featuredProduct ? `/${eventID}/promo` : `/${eventID}/store`}
            className="brandButton"
            style={{ backgroundColor: brandColor, color: "white" }}
          >
            Start Shopping
          </Link>
        </article>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
