import React, { useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import { AuthContext } from "../context/AuthContext";
import CartPopup from "./CartPopup";
// import { signOut } from "../auth";
import { getPriceString } from "../helpers";
import { BsHouseDoor } from "react-icons/bs";

const Header = ({ addLogo }) => {
  const {
    eventID,
    hideMobileNav,
    setHideMobileNav,
    logo,
    eventName,
    brandColor,
    dropShipCharge,
    customUnits,
    unlimitedBudget,
    discountPercentage,
  } = useContext(EventContext);
  const { signOut, userData } = useContext(AuthContext);
  const { dropShip, cartState } = useContext(CartContext);
  const headerRef = useRef();

  const url = window.location.href;
  const parts = url.split("/");
  const idEvent = parts[parts.length - 2];

  const eventLocal = userData?.events?.find(
    (event) => event.eventId === idEvent
  );

  if (!cartState) return null;
  // Calculate total and balance based on dropShip state
  const total =
    cartState.total + (dropShip && !customUnits ? dropShipCharge : 0);
  const lastPrice = total - total * (parseFloat(discountPercentage) / 100);
  const balance =
    discountPercentage && discountPercentage > 0
      ? Number(cartState.budget) +
        cartState.payPalApplied -
        lastPrice -
        (eventLocal?.payed || 0)
      : Number(cartState.budget) +
        cartState.payPalApplied -
        total -
        (eventLocal?.payed || 0);
  const numProducts = !!cartState.products
    ? Object.keys(cartState.products).length
    : 0;

  return (
    <div ref={headerRef} className="header fade-in">
      <div className="welcomeHeader">
        <div className="marquee">Welcome to the {eventName}!</div>
        <span className="logout" style={{ background: brandColor }}>
          <label onClick={() => signOut()}>Logout</label>
        </span>
      </div>

      <div className="storeHeader">
        {logo ? (
          <Link
            to={`/${eventID}/welcome`}
            style={{ backgroundImage: `url(${logo})` }}
            className="logo"
          />
        ) : (
          <Link to={`/${eventID}/welcome`}>
            <BsHouseDoor className="logo" />
          </Link>
        )}
        <span className="eventName">{eventName}</span>
        <span className="budgetInfo active">
          Total:{" "}
          {discountPercentage && discountPercentage > 0 ? (
            <strong>
              <strong style={{ textDecoration: "line-through" }}>
                {getPriceString(total, customUnits)}
              </strong>{" "}
              <strong>{getPriceString(lastPrice, customUnits)}</strong>
            </strong>
          ) : (
            <strong>{getPriceString(total, customUnits)}</strong>
          )}
          {!unlimitedBudget && " | Balance: "}
          {balance > 0 ? (
            <strong>
              {!unlimitedBudget && getPriceString(balance, customUnits)}
            </strong>
          ) : (
            <strong>$0.00</strong>
          )}
        </span>
        <Link to={`/${eventID}/cart`} className="cart">
          {cartState ? <span className="cartJewel">{numProducts}</span> : null}
        </Link>
        <span onClick={() => signOut()} className="logout">
          <label style={{ cursor: "pointer" }}>Logout</label>
        </span>
        <label
          style={{ cursor: "pointer" }}
          className="hamburger"
          onClick={() => setHideMobileNav(!hideMobileNav)}
        />

        {cartState && cartState.open ? (
          <CartPopup headerRef={headerRef} addLogo={addLogo} />
        ) : null}
      </div>
      <div className="thankYouHeader">...</div>
    </div>
  );
};

export default Header;
