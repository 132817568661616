import React, { createContext, useState } from "react";

export const DropShipContext = createContext();

export const DropShipProvider = ({ children }) => {
  const [dropShipData, setDropShipData] = useState();

  return (
    <DropShipContext.Provider
      value={{
        dropShipData,
        setDropShipData,
      }}
    >
      {children}
    </DropShipContext.Provider>
  );
};
