import { firestore } from "./firebaseConfig";
import { getSizeScale } from "./firestore";
import { getNzForSizeInSizeScale } from "./helpers";

export default async function updateProductAvailability(cartProducts, productList) {
  try {
    const eventProductListRef = firestore.collection("event-product-lists")
    const documentRef = await eventProductListRef.doc(productList).get()
    const data = documentRef.data()

    
    if (!data) {
      console.log("Event product list document does not exist.");
      return;
    }
    const updatedProducts = []
    const eventProducts = data.products || [];

    for (const eventProduct of eventProducts) {
      const cartProduct = cartProducts.find((cp) => cp.product === eventProduct.xID);
      if (cartProduct) {
      const size = await getSizeScale(cartProduct.product)
      const nz = getNzForSizeInSizeScale(cartProduct.size, size);

      let updatedAvailability = { ...eventProduct.availability };
      if (updatedAvailability[nz]) {
        updatedAvailability = {...updatedAvailability, [nz]: Math.max(0, updatedAvailability[nz] - cartProduct.quantity)}
      }
          updatedProducts.push({ ...eventProduct, availability: updatedAvailability });
        } else {
          updatedProducts.push(eventProduct)
        }
    }
    await documentRef.ref.update({ products: updatedProducts });
    console.log("Product availability updated successfully.");
  } catch (error) {
    console.log("Error updating product availability:", error);
  }
}
