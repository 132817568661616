import React, { useEffect, useContext, useCallback } from "react";

import CheckoutProduct from "./CheckoutProduct";
import { CartContext } from "../context/CartContext";

const Cart = () => {
  const { cartState } = useContext(CartContext);

  const renderEmptyCart = useCallback(() => {
    return <div className="emptyCartMessage">Your Shopping Cart is Empty</div>;
  }, []);

  useEffect(() => {
    document.body.className = "checkout";
    return () => {
      document.body.className = "";
    };
  }, []);

  // Render all the cart products
  const renderCart = useCallback(() => {
    if (!cartState || !cartState.products) return;
    return Object.keys(cartState.products).map((key) => {
      const cartProduct = cartState.products[key];
      return (
        <CheckoutProduct
          key={key}
          id={key}
          productID={cartProduct.product}
          size={cartProduct.size}
          quantity={cartProduct.quantity}
          embroidery={cartProduct.embroidery || []}
          addLogo={cartProduct.addLogo}
        />
      );
    });
  }, [cartState]);

  return (
    <>
      <div className="products">
        <h2 style={{"paddingLeft": "20px"}}>Cart</h2>
        <div className="alacarte">
          <div>
            {!cartState.products || !Object.keys(cartState.products).length
              ? renderEmptyCart()
              : renderCart()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
