import React, { useContext } from "react";
import { EventContext } from "../context/EventContext";

const OutOfService = () => {
  const { contactEmail } = useContext(EventContext);

  return (
    <div className="outofservice-message">
      <i className="logo"></i>
      <h1>Microsite Expired</h1>
      <h2>We're sorry, but the orders have been closed for this microsite.</h2>
      <p>Please contact the event organizer {!!contactEmail ? `at ${contactEmail}` : ``} if you still need to place an order.</p>
      <br />
      <p>If you would like to make a return, please visit</p>
      <a href={process.env.REACT_APP_RETURNS_URL}>{process.env.REACT_APP_RETURNS_URL}</a>
      {/* TODO make the number reflect an actual error */}
      {/* <p>Error Reference: 987345983</p> */}
    </div>
  );
}

export default OutOfService;
