import { auth, EmailAuthProvider } from "./firebase";
import { signInToEventWithPassword } from "./firestore";
import { firestore } from "./firebaseConfig";

// Sign in with Firebase
export const signIn = async (email, password) => {
  const credential = await auth.signInWithEmailAndPassword(email, password);
  const user = credential.user;
  return user;
};

// Create a user in Firebase Auth
export const createUserWithEmailAndPassword = async (email, password) => {
  console.log("Creating user in Firebase");
  const url = window.location.href;
  const parts = url.split("/");
  const eventId = parts[parts.length - 2];

  try {
    const PADDING = "xxxxxx";
    if (password.length < 6) password = password + PADDING;

    const userCredential = await auth.createUserWithEmailAndPassword(
      email,
      password
    );

    const additionalUserData = {
      email: userCredential.user.email,
      events: [
        {
          eventId,
          payed: 0,
        },
      ],
    };

    await firestore
      .collection("users")
      .doc(userCredential.user.uid)
      .set(additionalUserData);

    return {
      email: userCredential.user.email,
      id: userCredential.user.uid,
      events: [
        {
          eventId,
          payed: 0,
        },
      ],
    };
  } catch (err) {
    console.error("Error signing in:", err);
    throw new Error(
      `There was a problem signing in. Please contact the site administrator:\n\n${err}`
    );
  }
};

export const forgotPassword = async (email) => {
  console.log(`Sending email to ${email}`);
  return auth.sendPasswordResetEmail(email);
};

export const updatePassword = async (password) => {
  try {
    await auth.currentUser.updatePassword(password);
    console.log("Password changed successfully!");
    return true;
  } catch (err) {
    alert(`Error setting password: ${err}`);
    return false;
  }
};

// Confirm the current user's password
export const confirmCurrentPassword = async (password) => {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    password
  );

  return user
    .reauthenticateWithCredential(credential)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(`Error reauthenticating user: ${error}`);
      return false;
    });
};

// Sign in from email link
export const checkForEmailLink = async () => {
  console.log("checking for email link");
  // Confirm the link is a sign-in with email link.
  if (auth.isSignInWithEmailLink(window.location.href)) {
    const email = window.prompt("Please provide your email for confirmation");
    // The client SDK will parse the code from the link for you.
    try {
      const result = await auth.signInWithEmailLink(
        email.toLowerCase(),
        window.location.href
      );
      const user = result.user;
      const newUser = result.additionalUserInfo.isNewUser;
      return { ...user, isNew: newUser };
    } catch (error) {
      throw new Error(`Couldn't signing in with email link: ${error.message}`);
    }
  }
};

export const signInToEvent = async (email, password, eventID) => {
  const user = await signInToEventWithPassword(email, password, eventID);
  updateUser(user, eventID);
  return user;
};
export const updateUser = async (user, eventID) => {
  try {
    const userRef = firestore.collection("users").doc(user.uid);
    const userDoc = await userRef.get();
    if (!userDoc.exists) {
      throw new Error("User document does not exist in Firestore.");
    }
    const currentEvents = userDoc.data().events || [];
    const existingEvent = currentEvents.find(
      (event) => event.eventId === eventID
    );

    if (!existingEvent) {
      const newEvent = {
        eventId: eventID,
        payed: 0,
      };
      await userRef.update({
        events: [...currentEvents, newEvent],
      });

      console.log("New event added successfully to the user during sign-in.");
    } else {
      console.log("The event is already associated with the user.");
    }

    return user;
  } catch (error) {
    console.error("Error signing in and adding event:", error);
    throw error;
  }
};

export const signOut = () => {
  return auth.signOut();
};
